@import '../variables.scss';

.white {
  color: $white;
}

.black {
  color: $black;
}

.blue {
  color: $blue-80;
}

.darkblue {
  color: $blue-120;
}

.darkgray {
  color: $neutral-700;
}

.logo {
  width: 180px;
}

.hdLogo {
  width: 155px;
}

.hdMarketingLogo {
  width: 160px;
  height: 32px;
}
