@import '@hiredigital/ui/variables';

.enterpriseCompany {
  min-height: 700px;
  background-color: $primary-dark;
}

.newHero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  z-index: 0;

  background-color: $blue-150;
  background-image: url('/img/crystal.png');
}

.seo {
  background-image: url('/img/hero/main.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile {
  position: relative;
  padding: 10px 0;
  background-image: url('/img/hero/main.webp');
  background-color: $blue-100;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 150px;
}

.careers {
  height: 400px;
  background-image: linear-gradient(-180deg, hsla(0, 0%, 0%, 0) 100%, hsla(0, 0%, 0%, 1) 100%),
    linear-gradient(40deg, hsla(164, 65%, 69%, 1) 20%, hsla(210, 100%, 50%, 1) 100%),
    linear-gradient(-180deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 1) 120%),
    url('/img/hero/office.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color, color, multiply;

  @include for-size(phone-only) {
    height: 300px;
  }

  @include for-size(tablet-portrait-up) {
    height: 300px;
  }

  @include for-size(desktop-up) {
    height: 400px;

    .container {
      padding: 200px 0 20px;
    }
  }
}

// .faq {
//   position: relative;
//   background-color: $blue-100;

//   @include for-size(desktop-up) {
//     .container {
//       padding: 200px 20px 150px;
//     }
//   }

//   @include for-size(desktop-down) {
//     min-height: 0;

//     .container {
//       padding: 150px 20px 100px;
//     }
//   }
// }

// .faqTop {
//   margin: 0 auto;
//   text-align: center;
// }

.creator {
  position: relative;
  height: 550px;
  margin-bottom: -100px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 6vw));
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom right,
      hsla(214, 85%, 46%, 1),
      hsla(208, 89%, 47%, 1)
    );
    opacity: 0.6;
    z-index: $z-behind;
  }

  @include for-size(phone-only) {
    height: 600px;
  }
}

.about {
  position: relative;
  background-image: url('/img/hero/client.webp');
  background-repeat: no-repeat;
  background-size: cover;

  @include for-size(desktop-up) {
    .container {
      padding: 150px 20px;
    }
  }

  @include for-size(desktop-down) {
    min-height: 400px;
  }
}

.contact {
  position: relative;
  z-index: $z-background;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/img/people-meeting-bw.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: multiply;
    z-index: $z-behind;
    // z-index: $z-background;
    opacity: 0.3;
  }

  &:before {
    content: '';
    background-color: $blue-new-160;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-behind;
    mix-blend-mode: hard-light;
  }
}

.referral {
  position: relative;
  background-color: $blue-100;
}

.register {
  position: relative;
  background-color: $blue-150;

  min-height: 100vh;
  display: flex;
  align-items: center;

  @include for-size(phone-only) {
    background-color: $white;

    .waveContainer {
      display: none;
    }
  }
}

.companyRegister {
  position: relative;
  background-color: $primary-900;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.login {
  position: relative;
  background-color: $blue-150;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.talent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary-800;
  padding: 0;
  z-index: 0;
}

.client {
  position: relative;
  background-color: $blue-100;

  @include for-size(desktop-up) {
    .container {
      padding: 200px 20px 150px;
    }
  }

  @include for-size(desktop-down) {
    .container {
      padding: 150px 20px 100px;
    }
  }
}

.itemContainer {
  position: relative;
}
.angleLarge {
  position: absolute;
  // right: 0;
  left: -90px;
  top: 325px;
  border-top: 50px solid $primary-100;
  border-bottom: 100px solid $primary-100;
  border-right: 150px solid $primary-100;
  border-left: 200px solid $primary-100;
  transform: rotate(-40deg);
  opacity: 0.09;
  z-index: -1;
}
.angleLarge1 {
  position: absolute;
  // right: 0;
  left: -250px;
  top: -160px;
  border-top: 200px solid $primary-100;
  border-bottom: 200px solid $primary-100;
  border-right: 450px solid $primary-100;
  border-left: 450px solid $primary-100;
  transform: rotate(-40deg);
  opacity: 0.09;
  z-index: -1;
}

.angleRightLarge {
  position: absolute;
  // right: 0;
  right: -93px;
  top: 564px;
  border-top: 50px solid $primary-100;
  border-bottom: 100px solid $primary-100;
  border-right: 140px solid $primary-100;
  border-left: 200px solid $primary-100;
  transform: rotate(-40deg);
  opacity: 0.09;
  z-index: -1;
}
.angleRightLarge1 {
  position: absolute;
  right: -261px;
  top: 794px;
  border-top: 200px solid $primary-100;
  border-bottom: 200px solid $primary-100;
  border-right: 450px solid $primary-100;
  border-left: 450px solid $primary-100;
  transform: rotate(-40deg);
  opacity: 0.09;
  z-index: -1;
  // background: linear-gradient(to bottom, $primary-300 , $primary-900);
}

.rightAngleLarge {
  position: absolute;
  right: 0;
  top: -100px;
  border-top: 200px solid transparent;
  border-bottom: 200px solid transparent;
  border-right: 200px solid $primary-100;
  opacity: 0.15;
}
.rightAngleLarge1 {
  position: absolute;
  right: 0;
  top: -100px;
  border-top: 300px solid transparent;
  border-bottom: 300px solid transparent;
  border-right: 300px solid $primary-100;
  opacity: 0.05;
}
.rightAngleLarge2 {
  position: absolute;
  right: 0;
  top: -100px;
  border-top: 400px solid transparent;
  border-bottom: 400px solid transparent;
  border-right: 400px solid $primary-100;
  opacity: 0.05;
}
.rightAngleLarge3 {
  position: absolute;
  right: 0;
  top: -100px;
  border-top: 500px solid transparent;
  border-bottom: 500px solid transparent;
  border-right: 500px solid $primary-100;
  opacity: 0.05;
}
.waveContainer {
  pointer-events: none;
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 0;
  left: 0;

  @include ie-only {
    display: none;
  }
}

.container {
  max-width: $content-width + 40px;
  width: 100%;
  padding: 150px 20px 20px;
  margin: auto;
  display: flex;
  color: $white;
  box-sizing: border-box;
  flex-wrap: wrap;

  @include for-size(desktop-up) {
    padding: 150px 0 20px;
  }
}

.containerContact {
  padding: 140px 20px 40px 20px;
}

.main,
.downloads {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary-800;
  padding: 0;
  z-index: 0;
}

.heroLeft {
  flex-basis: 50%;

  @include for-size(tablet-portrait-down) {
    flex-basis: 100%;
  }
}

.heroRight {
  flex-basis: 50%;
  position: relative;

  @include for-size(tablet-portrait-down) {
    flex-basis: 100%;
  }
}

.enterprise {
  height: 800px;
  background-image: url('/img/hero/road.webp');
  background-color: hsla(220, 82%, 9%, 1);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  @include for-size(tablet-landscape-down) {
    height: auto;
  }
}

.vertical {
  height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: $z-background;
  background-image: linear-gradient(-180deg, $primary-900 100%, hsla(0, 0%, 0%, 1) 100%),
    linear-gradient(40deg, $primary-900 20%, $neutral-500 50%), url('/img/about-team.webp');
  background-blend-mode: color, multiply;

  &.large {
    height: 1000px;
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 600px;
    z-index: $z-behind;
    user-select: none;
    background-image: linear-gradient(to bottom, hsla(225, 91%, 9%, 0), $primary-dark);
  }
}

.newEnterprise {
  height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: $z-background;
  background-image: linear-gradient(-180deg, $primary-900 100%, hsla(0, 0%, 0%, 1) 100%),
    linear-gradient(40deg, $primary-900 20%, $neutral-500 50%),
    url('/img/enterprise/sky_background.webp');
  background-blend-mode: color, multiply;

  &.large {
    height: 1000px;
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    width: 100%;
    height: 600px;
    z-index: $z-behind;
    user-select: none;
    background-image: linear-gradient(to bottom, hsla(225, 91%, 9%, 0), $primary-dark);
  }
}

.bridge {
  // height: 800px;
  background-image: url('/img/hero/bridge.webp');
  background-color: hsla(220, 82%, 9%, 1);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  @include for-size(tablet-landscape-down) {
    height: auto;
  }
}

.engagement {
  background-image: url('/img/hero/bridge.webp');
  background-color: hsla(220, 82%, 9%, 1);
  background-size: cover;
  padding: 20px;
  padding-top: 80px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 120%;
  // height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include for-size(tablet-landscape-down) {
    height: auto;
  }
}

.curveLightblue {
  width: 100%;
  height: auto;
  bottom: -2px;
  color: $blue-5;
}

.bottom {
  max-width: $content-width;
  padding: 10px;
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
  padding-bottom: 30px;

  p {
    margin-top: 6px;
  }
}

.bottomMain {
  padding-top: 15px;
  padding-bottom: 40px;
}

.center {
  max-width: 610px;
  width: 80%;
  margin: auto;
  text-align: center;

  @include for-size(phone-only) {
    width: calc(100% - 40px);
    padding: 0px 20px;
  }
}

.shadow {
  text-shadow: 0 0 100px hsla(0, 0%, 0%, 1), 0 0 200px hsla(0, 0%, 0%, 1);
}

.cta {
  padding: 30px 0 20px;
}

//  NewHero

.blue {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $blue-100;
  padding: 0;
  z-index: 0;
}

.heroContainer {
  width: 100%;
  max-width: $content-width + 40px;
  margin: auto;
  z-index: 1;
  box-sizing: border-box;
}

.heroCenter {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include for-size(phone-only) {
    text-align: initial;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    align-items: inherit;
    justify-content: inherit;
  }
}

.heroTitle {
  margin: 0;
  color: $white;
  letter-spacing: -1px;
  font-size: $size-9;
  line-height: $line-height-9;
  font-weight: $fw-1;
  max-width: 850px;
  margin-bottom: 25px;
  width: 100%;

  @include for-size(tablet-landscape-down) {
    font-size: 40px;
    line-height: 48px;
  }

  @include for-size(phone-only) {
    font-size: 34px;
    line-height: 40px;
  }
}

.heroDescription {
  max-width: 660px;
  color: $white;
  margin-bottom: 30px;
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: $fw-1;
  width: 100%;

  @include for-size(phone-only) {
    font-size: 18px;
    line-height: 28px;
  }
}

.heroCta {
  @include for-size(tablet-landscape-down) {
    width: 100%;
  }
}
.heroBottom {
  width: 100%;
  box-sizing: border-box;
  max-width: $content-width + 40px;
  margin: auto;
  padding: 90px 0 20px;

  @include for-size(tablet-landscape-down) {
    padding-top: 30px;
  }
}

.splitHero {
  display: flex;
}
